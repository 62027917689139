import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
    individualSK: number;
    organizationSK: number;
    name: string;
    email: string;
    isPliEmployee: boolean;
    isGroupcast: boolean;
    isIndividual: boolean; 
    itemSK: number;
    isPM: boolean;
}

const initialState: UserState = {
    individualSK: 0,
    organizationSK: 0,
    name: '',
    email: '',
    isPliEmployee: false,
    isGroupcast: false,
    isIndividual: false, 
    itemSK: 0,
    isPM: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserState>) {
            state.individualSK = action.payload.individualSK;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.isPliEmployee = action.payload.isPliEmployee;
            state.isGroupcast = action.payload.isGroupcast;
            state.isIndividual = action.payload.isIndividual;
            state.itemSK = action.payload.itemSK;
            state.isPM = action.payload.isPM;
            state.organizationSK = action.payload.organizationSK;
        },
    },
});

export const { setUser } = userSlice.actions;

export const { reducer: userReducer } = userSlice;
