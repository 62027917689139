import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ProgramEvaluationSurveyFromWms } from '../ProgramEvaluationSurveyFromWms/ProgramEvaluationSurveyFromWms';
import { setIsSideBarDisabled } from '../Store/menuSlice';

type IProgramEvaluationPanelProps = {
    onSubmit?: () => void;
}

export const ProgramEvaluationPanel = ({ onSubmit }: IProgramEvaluationPanelProps) => {
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.lastSubmittedEncryptedRegistrationId ?? state.requestCredit.encryptedRegistrationId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setIsSideBarDisabled(true));
    }, [dispatch]);
    
    return (
        <ProgramEvaluationSurveyFromWms encryptedRegistrationId={encryptedRegistrationId} onSubmit={onSubmit} />     
    );
};

export default ProgramEvaluationPanel;